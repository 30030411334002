import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const MenuRUS = () => {
  return (
    <Layout>
      <SEO title="Meni Konoba Fakin" lang="rus" />
      <div className="content menu-page">
        <div className="naslov-container">
          <h1>Меню</h1>
          <div>
            <Link to="/menu">HR</Link>
            <Link to="/menu-en">EN</Link>
            <Link to="/menu-it">IT</Link>
            <Link to="/menu-de">DE</Link>
            <Link to="/menu-fr">FR</Link>
          </div>
        </div>
        <h3>Холодные закуски</h3>
        <ul>
          <li>Трюфельный крем с трюфельным сыром</li>
          <li>Салат из креветок, козий сыр и свежие огурцы</li>
          <li>Тартар из филе говядины с айоли и гренками</li>
          <li>Вителло Тоннато /«телятина тунцовая»/</li>
          <li>Паштет из печени с трюфелями и истрийской колбасой</li>
          <li>Домашнее прошутто с оливками</li>
          <li>
            Закусочная тарелка «Факин» на 2 персоны (домашнее прошутто, домашние
            колбаски, домашний сыр и трюфельный сыр)
          </li>
        </ul>
        <h3>Супы</h3>
        <ul>
          <li>Черный трюфельный суп</li>
          <li>Крем суп из спаржи</li>
          <li>Истрийская манестра</li>
        </ul>
        <h3>Теплые закуски</h3>
        <ul>
          <li>Фуджи с черными трюфелями</li>
          <li>Фритая с черными трюфелями</li>
          <li>Спагетти с креветками, мидиями и помидорами черри</li>
          <li>Паккери под соус болоньезе и со свежим хреном</li>
          <li>
            Римские ньокки в трюфельном соусе с чипсами из истрийской панчетты
          </li>
          <li>Гуляш из говядины с домашними клецками</li>
        </ul>
        <h3>Основные блюда</h3>
        <ul>
          <li>
            Обжаренное филе сибаса под корочкой с пряными травами, рататуем и
            картофельным пюре со шнитт-луком
          </li>
          <li>
            Куриное филе с начинкой из сыра, запеченное в духовке на основе
            шпината и шампиньонов
          </li>
          <li>
            Отбивная из баранины в тимьяновом соусе, жареный картофель с
            розмарином и овощами жульен
          </li>
          <li>Бифштекс с черными трюфелями на основе картофельного пюре</li>
          <li>
            Бифштекс в соусе из вина Теран с пюре из сельдерея и салатного
            гарнира
          </li>
          <li>
            Домашнее омболо /чистое мясо свиного каре/ с черным трюфелем и
            запечённым
          </li>
          <li>картофелем</li>
          <li>Овощи на гриле</li>
          <li>Рагу из дичи с запеченной полентой</li>
        </ul>
        <h3>Десерты</h3>
        <ul>
          <li>Шоколадное суфле</li>
          <li>Трюфельный шоколадный крем</li>
          <li>Шоколадные конфеты с начинкой и трюфелями</li>
          <li>Блинчики с апельсиновым соусом и Куантро</li>
          <li>Крем-брюле</li>
          <li>
            Ассорти на подносе с истрийскими сырами, оливковым маслом, медом и
            миндалем
          </li>
        </ul>
        <h3>Салаты</h3>
        <ul>
          <li>Трюфельный салат</li>
          <li>Ежедневный выбор сезонных салатов</li>
        </ul>
      </div>
    </Layout>
  )
}

export default MenuRUS
